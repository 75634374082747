import { render, staticRenderFns } from "./le-btn-preview-img.vue?vue&type=template&id=2de35c22&scoped=true&"
import script from "./le-btn-preview-img.vue?vue&type=script&lang=js&"
export * from "./le-btn-preview-img.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de35c22",
  null
  
)

export default component.exports