<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="sceneDetails">
            <div slot="header" class="a-fs-16 a-fw-b">{{ surveyStationInfo&&surveyStationInfo.positionName?surveyStationInfo.positionName:'-' }}</div>
            <div>
                <div class="a-flex-rfsc">
                    <span class="a-c-master a-fs-16 a-fw-b">现勘人员</span>
                    <img src="../../assets/icon/user-icon.png" style="width:32px;height:32px;margin: 0 11px 0 32px;" alt="">
                    <span class="a-c-master a-fs-16 a-fw-b">{{ sceneCheckInfo&&sceneCheckInfo.userName?sceneCheckInfo.userName:'-' }}</span>
                    <img src="../../assets/icon/phone-icon.png" style="width:32px;height:32px;margin: 0 11px 0 64px;" alt="">
                    <span class="a-c-master a-fs-16 a-fw-b">{{ sceneCheckInfo&&sceneCheckInfo.mobile?sceneCheckInfo.mobile:'-' }}</span>
                </div>
                <div class="a-c-master a-fs-16 a-fw-b mt64">站点基本信息</div>
                <div class="a-flex-rfsfs a-flex-wrap">
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">所在地区</span>
                        <span class="content a-c-master" v-if="!sceneCheckInfo.areaProvinceName&&!sceneCheckInfo.areaCityName&&!sceneCheckInfo.areaDistrictName">-</span>
                        <span class="content a-c-master" v-else>
                            {{ sceneCheckInfo&&sceneCheckInfo.areaProvinceName?sceneCheckInfo.areaProvinceName:'' }}
                            {{ sceneCheckInfo&&sceneCheckInfo.areaCityName?sceneCheckInfo.areaCityName:'' }}
                            {{ sceneCheckInfo&&sceneCheckInfo.areaDistrictName?sceneCheckInfo.areaDistrictName:'' }}
                        </span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">站点名称</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.positionName?surveyStationInfo.positionName:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">详细地址</span>
                        <span class="content a-c-master">{{ sceneCheckInfo&&sceneCheckInfo.buildAddress?sceneCheckInfo.buildAddress:'-' }}</span>
                    </div>
                </div>
                <div class="a-c-master a-fs-16 a-fw-b mt64">电桩数量型号</div>
                <div class="a-flex-rfsfs a-flex-wrap">
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">站点附近楼栋数量</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.nearbyBuildNum?surveyStationInfo.nearbyBuildNum:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">预估充电桩安装数量</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.pointNum?surveyStationInfo.pointNum:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">设备型号</span>
                        <span class="content a-c-master" v-if="surveyStationInfo.equipmentType==1">C30</span>
                        <span class="content a-c-master" v-else-if="surveyStationInfo.equipmentType==2">C20</span>
                        <span class="content a-c-master" v-else-if="surveyStationInfo.equipmentType==3">C21</span>
                        <span class="content a-c-master" v-else>-</span>
                    </div>
                </div>
                <div class="a-c-master a-fs-16 a-fw-b mt64">现勘信息</div>
                <div class="a-flex-rfsfs a-flex-wrap">
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">安装类型</span>
                        <span class="content a-c-master">{{ surveyStationInfo.installType | initDic(installTypeDic) }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">站点面积</span>
                        <span class="content a-c-master" v-if="surveyStationInfo.pileMeasure">{{ surveyStationInfo.pileMeasure }}平米</span>
                        <span class="content a-c-master" v-else>
                            长度：{{ surveyStationInfo.lon || 0 }}米；
                            宽度：{{ surveyStationInfo.wide || 0 }}米；
                        </span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">是否需要预埋</span>
                        <span class="content a-c-master" v-if="surveyStationInfo.embedded">是</span>
                        <span class="content a-c-master" v-else>否</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">是否需要做柜</span>
                        <span class="content a-c-master" v-if="surveyStationInfo.cabinet">是</span>
                        <span class="content a-c-master" v-else>否</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">施工安排建议</span>
                        <span class="content a-c-master">{{ surveyStationInfo.constructionSuggestion | initDic(constructionSuggestionDic) }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">取电方式</span>
                        <span class="content a-c-master">{{ surveyStationInfo.electricityWay | initDic(electricityWayDic) }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">现场照片</span>
                        <LeBtnPreviewImg class="content" :imgList="surveyStationInfo.sceneImage || []"></LeBtnPreviewImg>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">施工棚照片</span>
                        <LeBtnPreviewImg class="content" :imgList="surveyStationInfo.constructionImage || []"></LeBtnPreviewImg>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">下电表照片</span>
                        <LeBtnPreviewImg class="content" :imgList="surveyStationInfo.belowElectricityMeterImage || []"></LeBtnPreviewImg>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">施工棚最近门牌号照片</span>
                        <LeBtnPreviewImg class="content" :imgList="surveyStationInfo.constructionHouseNumberImage || []"></LeBtnPreviewImg>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">南方电网最近的牌子照片</span>
                        <LeBtnPreviewImg class="content" :imgList="surveyStationInfo.southernPowerGridBrand || []"></LeBtnPreviewImg>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">备注</span>
                        <span class="content a-c-master">{{ surveyStationInfo.remark || '-' }}</span>
                    </div>
                </div>
                <div class="a-c-master a-fs-16 a-fw-b mt64">安装环境</div>
                <div class="a-flex-rfsfs a-flex-wrap">
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">地面车棚</span>
                        <span class="content a-c-master" v-if="surveyStationInfo.bikeShedFlag==0">无</span>
                        <span class="content a-c-master" v-else-if="surveyStationInfo.bikeShedFlag==1">有</span>
                        <span class="content a-c-master" v-else>-</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">是否有手机信号</span>
                        <span class="content a-c-master" v-if="surveyStationInfo.phoneSignalFlag==1">是</span>
                        <span class="content a-c-master" v-else>否</span>                    
                    </div>
                    <!-- <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">安装位置长度(米)</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.installationPositionLength?surveyStationInfo.installationPositionLength:'-' }}</span>
                    </div> -->
                    <!-- <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">移动信号强弱</span>
                        <span class="content a-c-master" v-if="surveyStationInfo.mobileSignal==1">强</span>
                        <span class="content a-c-master" v-else-if="surveyStationInfo.mobileSignal==2">弱</span>
                        <span class="content a-c-master" v-else-if="surveyStationInfo.mobileSignal==3">非常弱</span>
                        <span class="content a-c-master" v-else>-</span>
                    </div> -->

                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">站点周围照片</span>
                        <div class="content">
                            <LeBtnPreviewImg :imgList="surveyStationInfo.nearbyPointImage || []"></LeBtnPreviewImg>
                        </div>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">现场车辆照片</span>
                        <div class="content">
                            <LeBtnPreviewImg :imgList="surveyStationInfo.sceneVehicleImage || []"></LeBtnPreviewImg>
                        </div>
                    </div>
                </div>
                <div class="a-c-master a-fs-16 a-fw-b mt64">安装勘测</div>
                <div class="a-flex-rfsfs a-flex-wrap" style="margin-bottom: 100px">
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">布线总长(米)</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.wiringDistance?surveyStationInfo.wiringDistance:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">车棚安装数量</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.stud?surveyStationInfo.stud:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">靠墙安装</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.baseOnWall?surveyStationInfo.baseOnWall:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">露天龙门架安装</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.openAir?surveyStationInfo.openAir:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">绿化带开槽总长度(米)</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.greenBeltLength?surveyStationInfo.greenBeltLength:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">水泥路面开槽总长度(米)</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.cementLength?surveyStationInfo.cementLength:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">沥青路面开槽总长度(米)</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.asphaltLength?surveyStationInfo.asphaltLength:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">大理石路面开槽总长度(米)</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.marbleLength?surveyStationInfo.marbleLength:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">架空电源引线长度(米)</span>
                        <span class="content a-c-master">{{ surveyStationInfo&&surveyStationInfo.overheadLength?surveyStationInfo.overheadLength:'-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">取电位置照片</span>
                        <div class="content">
                            <LeBtnPreviewImg :imgList="obtainPowerImage || []"></LeBtnPreviewImg>
                        </div>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">布线勘测照片</span>
                        <div class="content a-flex-rfsc">
                            <LeBtnPreviewImg :imgList="surveyStationInfo.mainLineImage || []"></LeBtnPreviewImg>
                        </div>
                    </div>
                    <div class="a-flex-rfsfs mt21 mr80">
                        <span class="title c79">电表安装位置图片</span>
                        <div class="content">
                            <LeBtnPreviewImg :imgList="surveyStationInfo.electricityMeterImage || []"></LeBtnPreviewImg>
                        </div>
                    </div>
                </div>
            </div>
            
        </el-card>
        <div class="a-line-t-e0 footerBox" v-if='surveyStationInfo&&surveyStationInfo.checkStatus == 2'>
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="audit(0)">&nbsp;&nbsp;&nbsp;通过&nbsp;&nbsp;&nbsp;</el-button>
            <el-button type="danger" class="a-ml-24 a-mt-15" @click="audit(1)" style="margin-left:24px;background: #FF3B30;">&nbsp;&nbsp;&nbsp;拒绝&nbsp;&nbsp;&nbsp;</el-button>
        </div>
        <AuditDialog ref="stationAudit" @auditFinish="auditFinish"></AuditDialog>
    </div>
    
</template>

<script>
import AuditDialog from './child/audit-dialog.vue'
import LeBtnPreviewImg from '../components/poppup/le-btn-preview-img.vue'
export default {
    data() {
        return {
            surveyAreaId: '',// 现勘小区id
            surveyStationId: '',//现勘小区站点id
            surveyStationInfo: '',//站点详情
            sceneCheckInfo: '',//现勘信息
            installTypeDic: [],//安装类型字典
            electricityWayDic: [],//取电方式字典
            constructionSuggestionDic: [],//施工建议字典
        };
    },
    mounted () {
        this.$getDic('installType').then(res=>{ this.installTypeDic = res; })
        this.$getDic('electricityWay').then(res=>{ this.electricityWayDic = res; })
        this.$getDic('constructionSuggestion').then(res=>{ this.constructionSuggestionDic = res; })
        this.surveyAreaId = this.$route.query.surveyAreaId
        this.surveyStationId = this.$route.query.surveyStationId
        this.sceneCheckInfo = window.localStorage.getItem('sceneCheckInfo')?JSON.parse(window.localStorage.getItem('sceneCheckInfo')):''
        this.getSurveyStationDetail()
    },
    computed: {
        obtainPowerImage () {
            let arr = []  
            if(this.surveyStationInfo.obtainPowerImage1){
                arr.push(this.surveyStationInfo.obtainPowerImage1)
            }
            if(this.surveyStationInfo.obtainPowerImage2){
                arr.push(this.surveyStationInfo.obtainPowerImage2)
            }
            if(this.surveyStationInfo.obtainPowerImage3){
                arr.push(this.surveyStationInfo.obtainPowerImage3)
            }
            return arr
        }
    },
    methods: {
        audit(type) {
            this.$refs['stationAudit'].dialogVisible = true 
            this.$refs['stationAudit'].id = this.surveyStationId
            this.$refs['stationAudit'].checkStatus = type
        },
        auditFinish () {
            this.getSurveyStationDetail()
        },
        getSurveyStationDetail () {
            this.$Axios._get({
                url: this.$Config.apiUrl.getSurveyStationDetail,
                method: "get",
                params: {
                    surveyAreaId: this.surveyAreaId,//现勘id
                    surveyStationId: this.surveyStationId,//现勘站点id 
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.surveyStationInfo = res.result.data
                    for (var i in this.surveyStationInfo) {
                        if(i == 'sceneImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'constructionImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'belowElectricityMeterImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'constructionHouseNumberImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'southernPowerGridBrand' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'electricityMeterImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'mainLineImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'sceneVehicleImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        if(i == 'nearbyPointImage' && this.surveyStationInfo[i]){
                            this.surveyStationInfo[i]=JSON.parse(this.surveyStationInfo[i])
                        }
                        
                    }
                }else{
                    this.$message.error(res.result.message)
                }
            })
        }
    },
    components: { AuditDialog, LeBtnPreviewImg }
}
</script>

<style lang="scss" scoped>
.sceneDetails{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 21px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 150px;
    margin-right: 16px;
}
.content{
    width: 267px
}
.footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    margin-bottom: 20px;
    padding-bottom: 20px
}
</style>