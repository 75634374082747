<template>
    <div>
        <div class="a-flex-rfsc" @click="showpreview">
            <img src="../../../assets/icon/img-icon.png" style="width:17px;height:17px;margin-right: 5px;" alt="">
            <span class="a-c-blue font-point">查看</span>
        </div>
        <le-preview-img v-model="showPreviewImg" :imgList="imgList"></le-preview-img>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPreviewImg: false,
        }
    },
    props: {
        imgList: {
            type: Array,
            default: []
        }
    },
    methods: {
        showpreview() {
            if (!this.imgList.length) {
                this.$message.warning('没有可预览文件或图片')
                return
            }
            this.showPreviewImg = true
        }
    }
}
</script>

<style lang="scss" scoped>
</style>