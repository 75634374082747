<template>
    <div>
        <el-dialog
            :title="checkStatus==0?'审核通过':'审核拒绝'"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            destroy-on-close
            width="500px">
            <el-form ref="form" :model="form" :rules='rules' label-position='right'>
                <el-form-item label="审核回执安装数量" prop='installNum' label-width="130px" v-if="checkStatus==0">
                    <el-input 
                        v-model="form.installNum" 
                        @input="form.installNum=form.installNum.replace(/^\.+|[^\d]+/g,'')">
                        </el-input>
                </el-form-item>
                <el-form-item label="回执内容" prop='receiptContent' label-width="130px" v-if="checkStatus==0">
                    <el-input 
                        type="textarea"
                        maxlength="150"
                        :autosize="{ minRows: 4}"
                        v-model="form.receiptContent">
                    </el-input>
                </el-form-item>
                <el-form-item label="" prop='refuseReason' label-width="0" v-if="checkStatus==1">
                    <el-input 
                        class="refuseInput"
                        maxlength="150"
                        type="textarea"
                        placeholder="请输入拒绝原因"
                        :autosize="{ minRows: 4}"
                        v-model="form.refuseReason">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                dialogVisible: false,
                id: '',// 审核id(站点id)
                checkStatus: '',// 审核类型
                form: {
                    installNum: '',// 安装数量
                    receiptContent: '',//回执内容
                    refuseReason: '',// 拒绝原因
                },
                rules: {
                    installNum: [{required: true, message:'请输入安装数量', trigger: 'blur'}],// 安装数量
                    receiptContent: [{required: true, message:'请输入回执内容', trigger: 'blur'}],//回执内容
                    refuseReason: [{required: true, message:'请输入拒绝原因', trigger: 'blur'}],// 拒绝原因
                }
            }
        },
        methods:{
            submit () {
                let txt = this.checkStatus==0?'是否确认审核通过？':'是否确认审核拒绝？'
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm(txt, '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.checkSurveyStation()
                        }).catch(_=>{ })
                    }
                })
                
            },
            checkSurveyStation () {
                
                this.$Axios._post({
                    url: this.$Config.apiUrl.checkSurveyStation,
                    method: "post",
                    params: {
                        id: this.id,
                        checkStatus: this.checkStatus,
                        installNum: this.checkStatus==0?this.form.installNum:'',// 安装数量
                        receiptContent: this.checkStatus==0?this.form.receiptContent:'',//回执内容
                        refuseReason: this.checkStatus==1?this.form.refuseReason:'',// 拒绝原因
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.$message.success('操作成功')
                        this.dialogVisible = false
                        this.$emit('auditFinish')
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.refuseInput{
    border: none

}
</style>